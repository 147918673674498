<template>
  <div>
    <a-row type="flex" justify="end" id="buttons">
      <a-col :span="3">
        <b-button pill variant="success" :to="{name: 'finished-product-manifests', params: {id: id}}">
          <div class="d-none d-md-block" v-b-tooltip.hover title="Regresar">
            <b-icon icon="arrow-left-circle"/> Regresar
          </div>
          <div class="d-md-none" v-b-tooltip.hover title="Regresar">
            <b-icon icon="arrow-left-circle"/>
          </div>
        </b-button>
      </a-col>
      <a-col :span="3">
        <b-button variant="warning" pill class="btn-block" @click="Print">
          <div class="d-none d-md-block">
            <b-icon icon="printer"/> Imprimir
          </div>
          <div class="d-md-none">
            <b-icon icon="printer"/>
          </div>
        </b-button>
      </a-col>
    </a-row>
    <my-spinner :load="fetching"/>
    <div v-if="!fetching">
      <h3 class="text-center">Manifiesto de Salida de Materiales</h3>
      <hr class="bg-primary">
      <h5>Datos del Residuo</h5>
      <hr class="bg-primary">
      <div class="row mb-4">
        <div class="col">
          <strong class="text-secondary">No.Lote: </strong>
          <span class="text-secondary">{{manifest.manifest_batch_code}}</span>
          <br>
          <strong class="text-secondary">No.Manifiesto de Salida y Disposición Fiscal: </strong>
          <span class="text-secondary">{{manifest.exit_manifest_code}}</span>
          <br>
          <strong class="text-secondary">Tipo de Formulación: </strong>
          <span class="text-secondary" v-if="manifest.formulation_types && manifest.formulation_types.name">
            {{manifest.formulation_types.name}}
          </span>
        </div>
        <div class="col">
          <strong class="text-secondary">Fecha de reciclaje: </strong>
          <span class="text-secondary">{{moment(manifest.recycle_dated_at).format('DD/MM/YYYY')}}</span>
          <br>
          <strong class="text-secondary">Cantidad Total: </strong>
          <span class="text-secondary">{{manifest.quantity}} -</span>
          <span v-if="manifest.ums && manifest.ums.name">{{manifest.ums.name}}</span>
        </div>
      </div>
      <h3 v-if="manifest.status_applied !== 'Aplica'">Datos de Transporte</h3>
      <hr class="bg-primary" v-if="manifest.status_applied !== 'Aplica'">
      <div class="row mb-4" v-if="manifest.status_applied !== 'Aplica'">
        <div class="col">
          <strong class="text-secondary">Empresa Transportista: </strong>
          <span class="text-secondary">{{manifest.carrier_company_name}}</span>
          <br>
          <strong class="text-secondary">Nombre del Chofer: </strong>
          <span class="text-secondary">{{manifest.carrier_company_driver}}</span>
          <br>
          <strong class="text-secondary">Tipo de Vehículo: </strong>
          <span class="text-secondary">{{manifest.vehicle_type}}</span>
          <br>
          <strong class="text-secondary">Placas de Tractocamion: </strong>
          <span class="text-secondary">{{manifest.vehicle_tag}}</span>
        </div>
        <div class="col">
          <strong class="text-secondary">Nombre de la Caja: </strong>
          <span class="text-secondary">{{manifest.vehicle_tag_coupled}}</span>
          <br>
          <strong class="text-secondary">No.Autorización SEMARNAT: </strong>
          <span class="text-secondary">{{manifest.semarnat_2}}</span>
          <br>
          <strong class="text-secondary">No.Secretaría Relaciones y Transportes: </strong>
          <span class="text-secondary">{{manifest.external_relations_secretary}}</span>
        </div>
      </div>
      <h3>Destinatario Final</h3>
      <hr class="bg-primary">
      <div class="row mb-4">
        <div class="col">
          <strong class="text-secondary">Tipo de Salida: </strong>
          <span class="text-secondary">{{manifest.exit_type}}</span>
          <br v-if="manifest.status_applied !== 'Aplica'">
          <strong class="text-secondary" v-if="manifest.status_applied !== 'Aplica'">
            No.Programación:
          </strong>
          <span class="text-secondary" v-if="manifest.status_applied !== 'Aplica'">
            {{manifest.programming_code}}
          </span>
          <br v-if="manifest.status_applied !== 'Aplica'">
          <strong class="text-secondary" v-if="manifest.status_applied !== 'Aplica'">
            Razón Social de la empresa de destino final:
          </strong>
          <span class="text-secondary" v-if="manifest.status_applied !== 'Aplica'">
            {{manifest.business_name}}
          </span>
          <br>
        </div>
        <div class="col">
          <template v-if="manifest.status_applied == 'No Aplica'">
              <strong class="text-secondary">Planta Destino: </strong>
              <span class="text-secondary">{{manifest.authorization_final_destiny}}</span>
              <br>
              <strong class="text-secondary">Punto de Alimentacción: </strong>
              <span class="text-secondary">{{manifest.feeding_point}}</span>
              <br>
          </template>
          <template v-else>
            <strong class="text-secondary">No.Autorización Destino Final: </strong>
            <span class="text-secondary">{{manifest.authorization_final_destiny}}</span>
            <br>
            <strong class="text-secondary">Manejo autorizado que dará la empresa al residuo: </strong>
            <span class="text-secondary">{{manifest.authorization_final_code}}</span>
          </template>
          <strong class="text-secondary">Responsable: </strong>
          <span class="text-secondary">{{manifest.responsible}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { apiV2Mixin } from '@/mixins/ApiV2Mixin'
export default {
  name: 'sampleDetail',
  mixins: [apiV2Mixin],
  data() {
    return {
      id: this.$route.params.id,
      // dom
      fetching: false,
      // manifest
      manifest: {
        id: 0,
        manifest_batch_code: '',
        exit_manifest_code: '',
        recycle_dated_at: '',
        quantity: '',
        carrier_company_name: '',
        carrier_company_driver: '',
        vehicle_type: '',
        vehicle_tag: '',
        vehicle_tag_coupled: '',
        semarnat_2: '',
        external_relations_secretary: '',
        exit_type: '',
        programming_code: '',
        business_name: '',
        authorization_final_destiny: '',
        authorization_final_code: '',
        responsible: '',
        is_blocked: '1',
        work_centers: {
          name: '',
        },
        ums: {
          name: '',
        },
        finished_product_manifest_mixtures: [
          {
            mixtures: {
              id: 2,
              code: '',
              name: '',
              row: '',
              drawer: '',
              total_formule_material: '',
              is_blocked: true,
              storage_area_finished_product: 'No Aplica',
              formulations: {
                code: '',
                operation_technical_1: '',
                operation_technical_2: '',
                worked_hours: '',
                started_at: '',
                finished_at: '',
                status: 'Concluido',
                total_containers: '',
                operation_shifts: {
                  code: '',
                  name: '',
                  initial_time: '',
                  final_time: '',
                },
                formulation_types: {
                  name: '',
                  details: '',
                },
                process_types: {
                  code: '',
                  name: '',
                },
              },
              currents: {
                name: '',
              },
              finished_product_areas: {
                name: '',
              },
            },
          },
        ],
      },
    }
  },
  methods: {
    async Fetch() {
      this.fetching = true
      const url = `finishedProductManifests/${this.id}`
      const manifest = await this.AxiosGetv2(url, {})
      if (manifest && manifest.data && Object.keys(manifest.data)) {
        this.manifest = manifest.data
      }
      this.fetching = false
    },
    Print() {
      window.print()
    },
  },
  async mounted() {
    await this.Fetch()
  },

}
</script>

<style>
@media print {
    #buttons, header, footer, aside, b-button {
        display: none;
    }
}
</style>
